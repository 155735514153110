/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import propertyService from 'services/property.service';
import CardProduct from 'components/Cards/CardProduct';
import FilterDropdownTypeB from 'components/Dropdowns/FilterDropdownTypeB';
// import { ViewOnMapsButton } from 'components/Buttons/Button';
import useViewersStore from 'stores/viewers';
import NoProperty from 'assets/techpolitan-no-property.svg';
import arrowRight from 'assets/arrow_right_24.svg';
import backgroundDesktop from 'assets/background-desktop.png';
import backgroundTablet from 'assets/background-tablet.png';
import backgroundMobile from 'assets/background-mobile.png';
import decorDesktop from 'assets/decor-desktop.svg';
import decorTablet from 'assets/decor-tablet.svg';
import decorMobile from 'assets/decor-mobile.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'styles/pages/home/home.css';

function Home() {
  const { viewers } = useViewersStore((state) => state);
  const [payload, setPayload] = useState({
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    location_id: null,
    price_selection_id: null,
    is_first: true,
    is_last: false,
  });
  const observerRef = useRef();
  const [propertyList, setPropertyList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [priceSelectionsList, setPriceSelectionsList] = useState([]);
  // const [seeMore, setSeeMore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchProperty({ page, limit, location_id, price_selection_id, sort_by, is_asc }) {
    setIsLoading(true);

    try {
      const payloadData = { page, limit, location_id, price_selection_id, sort_by, is_asc };
      const response = await propertyService.propertyList(payloadData);
      if (response?.message === 'Sukses') {
        setPayload({
          ...payload,
          page,
          limit,
          location_id,
          price_selection_id,
          sort_by,
          is_asc,
          is_first: response?.data?.is_first,
          is_last: response?.data?.is_last,
        });

        setPropertyList(response?.data?.content);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  }

  async function fetchMoreProperty({
    page,
    limit,
    location_id,
    price_selection_id,
    sort_by,
    is_asc,
  }) {
    setIsLoading(true);

    try {
      const response = await propertyService.propertyList({
        page,
        limit,
        location_id,
        price_selection_id,
        sort_by,
        is_asc,
      });
      if (response?.message === 'Sukses') {
        setPayload({
          ...payload,
          page,
          limit,
          location_id,
          price_selection_id,
          sort_by,
          is_asc,
          is_first: response?.data?.is_first,
          is_last: response?.data?.is_last,
        });

        setPropertyList((prevValue) => [...response.data.content, ...prevValue]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  }

  function fetchSeeMore() {
    fetchMoreProperty({ ...payload, page: payload.page + 1 });
  }

  async function fetchLocations() {
    try {
      const response = await propertyService.locations();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id?.toString(), label: x?.name };
      });

      setLocationList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchPriceSelections() {
    try {
      const response = await propertyService.priceSelections();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id?.toString(), label: x?.name };
      });
      setPriceSelectionsList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  function handleDropdown(param, value) {
    fetchProperty({
      ...payload,
      [param]: value,
      limit: 9,
      sort_by: 'id',
      is_asc: true,
      search: '',
      is_first: true,
      is_last: false,
    });
  }

  function handleClearFilter(param) {
    fetchProperty({
      ...payload,
      [param]: null,
      page: 1,
      limit: 9,
      sort_by: 'id',
      is_asc: true,
      search: '',
      is_first: true,
      is_last: false,
    });
  }

  useEffect(() => {
    fetchProperty(payload);
  }, []);

  useEffect(() => {
    fetchLocations();
    fetchPriceSelections();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !payload.is_last) {
          fetchSeeMore();
        }
      },
      { threshold: 1.0 },
    );

    if (observerRef?.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [isLoading]);

  // useEffect(() => {
  //   console.log(propertyList);
  // }, [propertyList]);

  return (
    <main className="main-container">
      <div className="home-section__a">
        <div className="home-section__a-upper">
          <img
            src={backgroundDesktop}
            alt="background-desktop"
            className="home-section__a-background-lg"
          />
          <img
            src={backgroundTablet}
            alt="background-tablet"
            className="home-section__a-background-md"
          />
          <img
            src={backgroundMobile}
            alt="background-mobile"
            className="home-section__a-background-sm"
          />
          {/* <img src={logo} alt="home-section__a-logo" className="home-section__a-logo" /> */}
          <div className="home-section__a-text-group">
            <h1 className="home-section__a-text-i">Your Gateway to</h1>
            <div className="home-section__a-text-ii-group">
              <div className="home-section__a-text-ii-left-group">
                <h1 className="home-section__a-text-ii left">Underpriced</h1>
                <img src={decorDesktop} alt="decor" className="decor-lg" />
                <img src={decorTablet} alt="decor" className="decor-md" />
                <img src={decorMobile} alt="decor" className="decor-sm" />
              </div>
              <div className="home-section__a-text-ii-spacer" />
              <h1 className="home-section__a-text-ii right">Properties</h1>
            </div>
            <p className="home-section__a-text-iii">
              Masuki dunia investasi properti dengan harga terjangkau! Dapatkan penawaran terbaik
              dan raih impian properti Anda sekarang!
            </p>
          </div>
        </div>
        <div className="home-section__a-bottom">
          <div className="card-filter-outer">
            <div className="card-filter-outer__text-box">
              <h3 className="card-filter-outer__text">
                Investasi Properti Menjanjikan di{' '}
                <span className="app-color-primary">Lokasi Strategis!</span>
              </h3>
              <h4 className="card-filter-outer__text">
                <span className="app-color-primary">{viewers}</span> people are viewing this page
              </h4>
            </div>
            <div className="filter-type-b">
              <FilterDropdownTypeB
                name="Lokasi"
                paramName="location_id"
                id="location_id"
                handleDropdown={handleDropdown}
                handleClearFilter={handleClearFilter}
                options={locationList}
                className="filter-location"
              />
              <FilterDropdownTypeB
                name="Harga"
                paramName="price_selection_id"
                id="price_selection_id"
                handleDropdown={handleDropdown}
                handleClearFilter={handleClearFilter}
                options={priceSelectionsList}
                className="filter-price"
              />
            </div>

            {/* <div className="card-filter-inner">
              <h6 className="card-filter-inner__title">
                Investasi Properti Menjanjikan di{' '}
                <span className="app-color-primary">Lokasi Strategis!</span>
              </h6>
              <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 m-0 p-0 filter-property-container">
                    <FilterDropdown
                      name="Mencari apa"
                      paramName="property_type_id"
                      id="property_type_id"
                      handleDropdown={handleDropdown}
                      handleClearFilter={handleClearFilter}
                      options={propertyTypesList}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 m-0 p-0 filter-property-container">
                    <FilterDropdown
                      name="Lokasi"
                      paramName="city_id"
                      id="city_id"
                      handleDropdown={handleDropdown}
                      handleClearFilter={handleClearFilter}
                      options={locationList}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 m-0 p-0 filter-property-container">
                    <FilterDropdown
                      name="Harga"
                      paramName="price_selection_id"
                      id="price_selection_id"
                      handleDropdown={handleDropdown}
                      handleClearFilter={handleClearFilter}
                      options={priceSelectionsList}
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="home-section__b">
        {propertyList && propertyList?.length > 0 && (
          <div className="home-section__b-properties">
            {propertyList.map((property) => {
              return <CardProduct key={`content-${property?.id}`} detail={property} />;
            })}
          </div>
        )}

        {(!propertyList || propertyList.length === 0) && (
          <div className="d-flex flex-column justify-content-start align-items-center w-100 my-5">
            <img src={NoProperty} alt="no-data" />
            <p
              style={{
                marginTop: '26.16px',
                lineHeight: '19.5px',
                letterSpacing: '-0.43px',
                color: '#979797',
                textAlign: 'center',
              }}
            >
              Properti tidak ditemukan. Belum ada hasil yang sesuai dengan pencarian Anda.
            </p>
          </div>
        )}

        {isLoading ? (
          <div className="d-flex flex-row justify-content-center align-items-center">
            Loading ...
          </div>
        ) : null}

        {propertyList.length > 0 ? (
          <div className="d-flex flex-row justify-content-center" ref={observerRef}></div>
        ) : null}
      </div>

      <div className="home-section__c">
        <div className="home-section__c-content">
          <h4 className="text">
            Jadi yang pertama dapatkan update terkini properti terbaik dan strategis
          </h4>
          <Link to="/property-cooperation" className="cta-button">
            <img src={arrowRight} className="arrow-right" alt="arrow-right" />
            <p className="text">Kerjasama Properti</p>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Home;
