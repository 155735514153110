/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from 'assets/logo--rumah-kita.svg';
import arrowRight from 'assets/arrow--right.svg';
import 'styles/components/header/header.css';

export default function PageHeader() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header_container${scrolled ? ' scrolled' : ''}`}>
      <div className="header-container__content-wrapper">
        <Link className="header-logo_container" to="/">
          <img src={logo} alt="client-logo" className="header-logo" />
        </Link>{' '}
        {location.pathname === '/property-cooperation' ? null : (
          <Link to="/property-cooperation" className="cta-property-cooperation__btn">
            <img src={arrowRight} className="arrow-right" alt="arrow-right" />

            <span className="text">Kerjasama Properti</span>
          </Link>
        )}
      </div>
    </header>
  );
}
