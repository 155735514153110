import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortenNumber } from '../../utils/common';
import defaultImage from '../../assets/pexels-pixabay-164558 2.png';
import marker from '../../assets/techpolitan-marker.svg';
import '../../styles/components/cards/card-product.css';

function CardProduct({ detail }) {
  return (
    <div className="card-product">
      <div className="card-product__header">
        <Link to={`/properties/${detail.id}`}>
          <img src={detail.cover_image_url || defaultImage} alt="property" />
        </Link>
      </div>
      <div className="card-product__body">
        <Link to={`/properties/${detail.id}`} className="name">
          {detail?.name?.length > 18 ? `${detail.name.slice(0, 18)}...` : detail.name}
        </Link>
        <div className="location">
          <img src={marker} alt="location" />
          <span className="text">{detail.location}</span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="price">
            {detail && detail.price ? (
              <div className="normal">Rp {shortenNumber(detail?.price)}</div>
            ) : (
              <div className="normal">Contact for Price</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const product = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
};

CardProduct.propTypes = {
  detail: PropTypes.shape(product).isRequired,
};

export default CardProduct;
