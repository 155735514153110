/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaXTwitter, FaInstagram } from 'react-icons/fa6';
import logoWhite from 'assets/logo--rumah-kita-white.svg';
import 'styles/components/footer/footer.css';

export default function Footer() {
  return (
    <footer className="footer_container">
      <div className="footer_container__content">
        <div className="row m-0 p-0 w-100">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6 p-0 m-0">
            <div className="footer_logo">
              <img src={logoWhite} alt="logo-white" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6 p-0 m-0 d-flex mt-3 mt-sm-3 mt-md-3 mt-lg-0 mt-xl-0 mt-xxl-0 flex-column align-items-start align-items-sm-start align-items-md-start align-items-lg-end align-items-xl-end align-items-xxl-end">
            <div>
              <div className="mb-3">
                <Link to="https://x.com/" className="me-3">
                  <FaXTwitter color="#ffffff" fontSize="25" />
                </Link>
                <Link to="https://facebook.com/" className="me-3">
                  <FaFacebookF color="#ffffff" fontSize="23" />
                </Link>
                <Link to="https://www.instagram.com/">
                  <FaInstagram color="#ffffff" fontSize="26" />
                </Link>
              </div>
              <div className="footer_rights">
                <p className="web-name">RumahKita Property Marketplace </p>
                <p className="trademark">© 2024 PT RumahKita | All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6 p-0 m-0">
            <div className="footer_logo">
              <img src={logoWhite} alt="logo-white" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6 p-0 m-0 d-flex mt-3 mt-sm-3 mt-md-3 mt-lg-0 mt-xl-0 mt-xxl-0 flex-column align-items-start align-items-sm-start align-items-md-start align-items-lg-end align-items-xl-end align-items-xxl-end">
            <div>
              <div className="mb-3">
                <Link to="https://x.com/" className="me-3">
                  <FaXTwitter color="#ffffff" fontSize="25" />
                </Link>
                <Link to="https://facebook.com/" className="me-3">
                  <FaFacebookF color="#ffffff" fontSize="23" />
                </Link>
                <Link to="https://www.instagram.com/">
                  <FaInstagram color="#ffffff" fontSize="26" />
                </Link>
              </div>
              <div className="footer_rights">
                <p className="web-name">RumahKita Property Marketplace </p>
                <p className="trademark">© 2024 PT RumahKita | All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
}
